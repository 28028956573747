import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CancelButton, Icons } from "@src/components"

const PaymentItem = ({ onClose }) => (
  <Box position="relative">
    <Typography variant="body1" lineHeight="24px">
      Với tiêu chí không ngừng nỗ lực để trải nghiệm mua hàng của Quý khách diễn
      ra thuận lợi hơn, The C.I.U hiện hỗ trợ 2 hình thức thanh toán tại Website
      như sau:
    </Typography>
    <Typography
      sx={{
        display: "list-item",
        marginLeft: "20px"
      }}
      variant="body1"
      lineHeight="24px"
    >
      Thanh toán khi nhận hàng (COD): quý khách nhận hàng, kiểm tra hàng và
      thanh toán trực tiếp cho nhân viên giao hàng
    </Typography>
    <Typography
      sx={{
        display: "list-item",
        marginLeft: "20px"
      }}
      variant="body1"
      lineHeight="24px"
    >
      Thanh toán Online: qua hình thức chuyển khoản hoặc ví điện tử Momo, VNPAY
    </Typography>
    <CancelButton
      type="button"
      height="40px"
      label="Thu gọn"
      endIcon={<Icons name="arrow-up" />}
      minHeight="40px"
      minWidth="160px"
      sx={{
        margin: {
          xs: "24px auto 0",
          md: "48px auto 0"
        }
      }}
      onClick={onClose}
    />
  </Box>
)
export default PaymentItem
