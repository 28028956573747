"use client"

import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Collapse from "@mui/material/Collapse"
import { useState, useRef } from "react"
import { Icons } from "@src/components"
import HomeSectionContainer from "@src/sections/home/home-section-container"
import ChangeItem from "./policyItem/change"
import DeliveryItem from "./policyItem/delivery"
import HeadphoneItem from "./policyItem/headphone"
import PaymentItem from "./policyItem/payment"

const HeightConfig = ["auto", 256, 320, 850, 352]

const PolicyConfig = [
  {
    id: 1,
    title: "Thanh toán",
    iconName: "payment"
  },
  {
    id: 2,
    title: "Vận chuyển",
    iconName: "delivery"
  },
  {
    id: 3,
    title: "Trả hàng - Hoàn tiền",
    iconName: "change"
  },
  {
    id: 4,
    title: "Hỗ trợ dịch vụ",
    iconName: "headphone"
  }
]

const WrapperItemPolicy = props => {
  const { active, iconName, name, onClick } = props
  return (
    <Stack
      sx={{
        borderRadius: "16px",
        padding: {
          xs: "0",
          md: "10px 20px"
        },
        backgroundColor: "white",
        width: "fit-content",
        cursor: "pointer",
        flex: {
          xs: "1",
          md: "auto"
        }
      }}
      direction={{
        xs: "column",
        md: "row"
      }}
      justifyContent="flex-start"
      alignItems="center"
      role="button"
      position="relative"
      height="100%"
      onClick={onClick}
    >
      {active && (
        <Icons
          sx={{
            position: "absolute !important",
            top: {
              xs: 90,
              md: 148
            },
            left: {
              xs: "50%",
              md: "60%"
            },
            transform: "translateX(-50%)",
            zIndex: 1
          }}
          color="white"
          name="rectangle"
        />
      )}
      <Icons
        sx={{
          width: 52,
          height: 52,
          padding: 1,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: {
            xs: active ? "#686868" : "#434343",
            md: "transparent"
          },
          border: {
            xs: active ? "4px solid #ECECEC" : "4px solid white",
            md: "none"
          },
          svg: {
            color: {
              xs: "white",
              md: "black"
            }
          }
        }}
        name={iconName}
      />
      <Typography
        fontSize={{
          xs: 12,
          md: 14
        }}
        textAlign="center"
        variant="span"
        zIndex={10}
        paddingTop={{
          xs: 1,
          md: 0
        }}
        paddingRight={{
          xs: 0,
          md: 1
        }}
      >
        {name}
      </Typography>
      <Icons
        display={{
          xs: "none",
          md: "block"
        }}
        name="arrow-down"
      />
    </Stack>
  )
}

const PolicyList = () => {
  const [collapseActive, setCollapseActive] = useState(0)
  const prevHeight = useRef(0)

  const handleChangeCollapse = index => () => {
    if (collapseActive === index) {
      setCollapseActive(0)
      return
    }
    prevHeight.current = HeightConfig[index]
    setCollapseActive(index)
  }

  const handleCloseCollapse = () => {
    setCollapseActive(0)
  }

  const checked = collapseActive !== 0

  return (
    <>
      <HomeSectionContainer
        sxWrapper={{
          margin: {
            xs: "16px",
            md: "0px"
          }
        }}
        sx={{
          backgroundColor: {
            xs: "white",
            md: "#434343"
          }
        }}
      >
        <Stack
          maxWidth="lg"
          margin="auto"
          alignItems="center"
          justifyContent="center"
          spacing={{
            xs: 1,
            md: 4
          }}
          height="100%"
        >
          {PolicyConfig.map(item => (
            <WrapperItemPolicy
              key={item.id}
              name={item.title}
              iconName={item.iconName}
              active={collapseActive === item.id}
              onClick={handleChangeCollapse(item.id)}
            />
          ))}
        </Stack>
      </HomeSectionContainer>
      <HomeSectionContainer
        sx={{
          backgroundColor: "transparent",
          padding: 0,
          margin: "0px auto !important"
        }}
        sxWrapper={{
          margin: 0
        }}
      >
        <Collapse in={checked}>
          <Paper
            margin="auto"
            maxWidth="lg"
            sx={{
              backgroundColor: {
                xs: "transparent",
                md: "white"
              },
              borderRadius: "16px",
              padding: {
                xs: "0px 28px 16px",
                md: "48px 32px"
              },
              transition: "height 0.5s ease-in-out",
              height: checked ? "auto" : prevHeight.current,
              margin: {
                xs: "16px 0px 0px",
                md: "48px auto 0px"
              },
              boxShadow: "none"
            }}
          >
            {collapseActive === 1 && (
              <PaymentItem onClose={handleCloseCollapse} />
            )}
            {collapseActive === 2 && (
              <DeliveryItem onClose={handleCloseCollapse} />
            )}
            {collapseActive === 3 && (
              <ChangeItem onClose={handleCloseCollapse} />
            )}
            {collapseActive === 4 && (
              <HeadphoneItem onClose={handleCloseCollapse} />
            )}
          </Paper>
        </Collapse>
      </HomeSectionContainer>
    </>
  )
}

export default PolicyList
