"use client"

import { SectionContainer } from "@src/components"
import Container from "@mui/material/Container"

const HomeSectionContainer = ({ children, sx = {}, sxWrapper = {} }) => (
  <SectionContainer
    sxWrapper={{
      backgroundColor: "transparent",
      ...sxWrapper
    }}
    sx={{
      backgroundColor: "white",
      borderRadius: "16px",
      maxWidth: {
        xs: "100%",
        md: "calc(100% - 96px)"
      },
      margin: {
        xs: "auto 0px",
        md: "auto 48px"
      },
      padding: {
        xs: "8px",
        md: "24px 16px"
      },
      ...sx
    }}
    maxWidth={false}
  >
    <Container
      width="100%"
      maxWidth="xl"
      sx={{
        paddingX: {
          xs: 0,
          md: 4
        }
      }}
    >
      {children}
    </Container>
  </SectionContainer>
)

export default HomeSectionContainer
