import React from "react"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import NextImage from "next/image"

const EmptyProduct = () => {
  return (
    <Stack
      paddingY="80px"
      paddingX="16px"
      width="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Box
        sx={{
          width: 240,
          height: 240,
          position: "relative",
          overflow: "hidden"
        }}
      >
        <NextImage
          src="/assets/images/products/empty-products.png"
          width={240}
          height={240}
        />
      </Box>

      <Typography variant="body1" sx={{ textAlign: "center", fontWeight: 700 }}>
        Chương trình Sale off sắp diễn ra!
      </Typography>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Chương trình Sale off của The C.I.U sẽ diễn ra trong thời gian sắp tới,
        bạn cùng theo dõi và đón mua nhé!
      </Typography>
    </Stack>
  )
}

export default EmptyProduct
